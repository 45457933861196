/* Ensure Swiper container has enough height */
.swiper-container {
    position: relative;
    height: 70vh; /* Adjust height as needed */
    overflow: hidden; /* Prevent overflow if necessary */
  }
  
  /* Style the pagination container */
  .swiper-pagination {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20px; /* Adjust positioning as needed */
    width: 100%;
    z-index: 10; /* Ensure it's above the images */
  }
  
  /* Style the pagination bullets */
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 18px;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    margin: 0 5px; /* Adjust spacing between bullets */
  }
  
  /* Style the active pagination bullet */
  .swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
  }
  